export default [
  /* */
  { name: '明志書苑', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '市心得藝', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '',offset:0, mobileOffset: 0 , type: '' },
  { name: '城森綠藝', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '交通快藝', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '匠心築藝', imgSrc: '', subTitle: '', section: 'section9', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '空間涵藝', imgSrc: '', subTitle: '', section: 'section10', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '公設', imgSrc: '', subTitle: '', section: 'section11', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  // { name: '樣品屋', imgSrc: '', subTitle: '', section: 'section12', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
 
  { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
  { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
  { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
]
