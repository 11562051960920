export default {
  data() {
    return {
      slideIndex: 0,
      slideIndex1: 0,
      slideIndex2: 0,
      slideIndex3: 0,
      slideIndex4: 0,
      toggleTimer: true,
      stopAutoPlay: true,
    }
  },

  mounted() {
    setInterval(() => {
      if (this.toggleTimer) {
        if (this.slideList) {
          this.addIndex()
        }
        if (this.slideList1 && !this.stopAutoPlay) {
          this.addMultiIndex(1)
        }

        if (this.slideList2 && !this.stopAutoPlay) {
          this.addMultiIndex(2)
        }

        if (this.slideList3 && !this.stopAutoPlay) {
          this.addMultiIndex(3)
        }

        if (this.slideList4 && !this.stopAutoPlay) {
          this.addMultiIndex(4)
        }
      }

    }, 5000)
  },
  methods: {
    goTo(index) {
      this.slideIndex = index
    },
    goToMultiIndex(listIndex, index) {
      this[`slideIndex${listIndex}`] = index
    },
    addIndex() {
      this.slideIndex =
        this.slideIndex === this.slideList.length - 1 ? 0 : this.slideIndex + 1
    },

    decIndex() {
      this.slideIndex =
        this.slideIndex === 0 ? this.slideList.length - 1 : this.slideIndex - 1
    },
    // 一個區塊有多個輪播組件
    addMultiIndex(index) {
      this[`slideIndex${index}`] =
        this[`slideIndex${index}`] === this[`slideList${index}`].length - 1 ? 0 : this[`slideIndex${index}`] + 1
    },

    decMultiIndex(index) {
      this[`slideIndex${index}`] =
        this[`slideIndex${index}`] === 0 ? this[`slideList${index}`].length - 1 : this[`slideIndex${index}`] - 1
    },
  },
}
