import { isMobile } from '../utils/index'
export default {
  address: '新北市泰山區明志路二段278號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1807.3103280291143!2d121.42606182936099!3d25.046944600000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a7c638dfad8f%3A0x84e60da1b2cb298b!2zMjQz5paw5YyX5biC5rOw5bGx5Y2A5piO5b-X6Lev5LqM5q61Mjc46Jmf!5e0!3m2!1szh-TW!2stw!4v1660267112435!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/zQXELpaYR5y5Ej2d8',
  phone: '02-2904-6888',
  fbLink: 'https://www.facebook.com/103007875810421',
  fbMessage: 'https://m.me/103007875810421',
  caseName: '明志書苑',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '茂德建設股份有限公司',],
    ['建築設計', '陳朝雄建築師事務所',],
  ],

  gtmCode: ['W7SX6CS'], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: '',
    room_type: ['兩房','三房'],//房型
  }
}
